<template>
    <div class="content">
      <div style="width: 100%;display: flex;flex-direction: row;align-items: center;">
        <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="固定车延期退费记录" :fetch="createExportData" :before-finish="finishDownload" worksheet="固定车延期退费记录">
            <el-button type="primary" class="el-icon-download"> 导出</el-button>
        </JsonExcel>

        <span style="margin-left: 3rem;font-size: 14px;">合计：
          <span style="color: red;">{{sumcharges}} </span> 元
        </span>
      </div>

      <el-card style="margin-top:15px;width:100%" class="box-card">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div class="search">
                <label>时间：</label>
                 <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>车牌号:</label>
                <el-input v-model="searchForm.plateno" class="marg" style="width:150px"></el-input>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>操作员：</label>
                <el-select v-model="searchForm.user_id" style="width:150px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option v-for="item in UserDataList" :key="item.user_id" :label="item.user_name" :value="item.user_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <div class="search">
                  <label>操作：</label>
                  <el-select v-model="searchForm.opt_type" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="延期" :value="1"></el-option>
                    <el-option label="退费" :value="2"></el-option>
                  </el-select>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div v-if="User.user_type!==5" class="search">
                <label>车场：</label>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                  <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                  <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="2">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
      </el-card>

      <el-table ref="tb" :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='100%' stripe :highlight-current-row='true' size="mini">
        <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场"></el-table-column>
        <el-table-column align="center" prop="log_time" label="时间" min-width="145px">
            <template slot-scope="scope">
              {{FormData(scope.row.log_time)}}
            </template>
        </el-table-column>
        <el-table-column align="center" prop="log_plateno" label="车牌号"></el-table-column>
        <el-table-column align="center" prop="card_name" label="卡型"></el-table-column>
        <el-table-column align="center" prop="log_old_time" label="原有效期">
          <template slot-scope="scope">
            {{FormData(scope.row.log_old_time)}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="log_new_time" label="新有效期">
          <template slot-scope="scope">
            {{FormData(scope.row.log_new_time)}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="opt_name" label="操作"></el-table-column>
        <el-table-column align="center" prop="user_name" label="操作员"></el-table-column>
        <el-table-column align="center" prop="log_charge" label="费用">
          <template slot-scope="scope">
            {{toDecimal2(scope.row.log_charge)}}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="width:100%;" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @size-change="handleSizeChange" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  
    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'


export default{
    data:function(){
        return{
            User:null,
            searchForm:{
                pageNo:1,
                pageSize:15,
                total:0,
                pk_id:0,
                opt_type:0,
                time:[],
                plateno:'',
                user_id:0,
            },
            UserDataList:[],
            DataList:[],
            sumcharges:0,

            exportDataStandard: {
              所属车场: "park_name" ,
              时间: {
                field: 'log_time',
                callback: value => {
                  return this.FormData(value)
                }
              },
              车牌号:'log_plateno',
              卡型: "card_name" ,
              操作: "opt_name" ,
              操作员: "user_name" ,
              费用:'log_charge'
            },
            exportData: []
        }
    },
    computed:{
      FormData () {
          return function (time) {
            if(time===null||time===undefined||time==='')
              return ''
            else
              return util.FormateDate(time, 2)
          }
        },
      toDecimal2(){
        return function(num){
          if(num===null){num=0}
          return util.toDecimal2(num)
        }
      },
    },
    components: {
      JsonExcel
    },
    created(){
        this.User = util.JesToken()
        this.getParkDataList()
    },
    methods:{
        getParkDataList(){
            if(this.User.user_type===5){
                this.GetDataList()
                this.GetUserDataList()
            }
            else{
                util.Get('parkmanage/getparkdatalist').then(res => {
                    if (res.rpStatus === 10000) {
                        this.ParkDataList = res.list
                        if(res.list.length>0){
                            this.searchForm.pk_id=res.list[0].pk_id
                            this.GetDataList()
                            this.GetUserDataList()
                        }
                    }
                })
            }
        },
        GetUserDataList(){
            util.Get('user/getuserdataList?id='+this.searchForm.pk_id+'&userType=5').then(res=>{
                if(res.rpStatus===10000)
                    this.UserDataList=res.list
            })
        },

        parkChanged(value){
            this.searchForm.pk_id=value
            this.GetDataList()
            this.searchForm.user_id=0
            this.GetUserDataList()
        },
        GetDataList(){
            this.searchForm.begintime=''
            this.searchForm.endtime=''
            if(this.searchForm.time!=undefined&&this.searchForm.time!==null&&this.searchForm.time.length>0){
                this.searchForm.begintime=this.searchForm.time[0]
                this.searchForm.endtime=this.searchForm.time[1]
            }

            util.Get('logdelay/getlogdelaydataList',this.searchForm).then(res=>{
                if(res.rpStatus===10000){
                    this.sumcharges=res.rpMsg
                    this.searchForm.total=res.total
                    this.DataList=res.list
                   
                }
            })
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.GetDataList()
        },
        handleSizeChange(pageSize){
            this.searchForm.pageSize=pageSize
            this.GetDataList()
        },
        Search(){
            this.GetDataList()
        },

        // 导出
        async createExportData () {
          this.searchForm.pageNo = 0
          var form = JSON.parse(JSON.stringify(this.searchForm))
          form.pageNo = 0

          var result = await util.Get('logdelay/getlogdelaydataList', form)
          if (result.rpStatus === 10000) {
            this.exportData = result.list
            return this.exportData
          }
        },
        finishDownload () {
          this.$message({
            message: '导出数据成功',
            type: 'success'
          })
          this.exportData=[]
        }
    }
}
</script>


<style scoped>
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
    .el-table{
        margin-top: 15px;
        overflow: visible !important;
    }
    .search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
</style>